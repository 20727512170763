import {Line, mixins} from 'vue-chartjs'

const {reactiveProp} = mixins
// https://chenhailun.gitbooks.io/chartjs/content/di-wu-zhang-zuo-biao-zhou.html
export default {
    extends: Line,
    mixins: [reactiveProp],
    props: ['options'],
    mounted() {
        // this.chartData 在 mixin 创建.
        // 如果你需要替换 options , 请创建本地的 options 对象
        this.renderChart(this.chartData, this.options)
    }
}